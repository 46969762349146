import React from "react"
import "./styles.scss"

const sectionSubtitle = ({ text, isDark }) => (
  <div className="section-subtitle">
    <h2
      data-aos="custom-fade"
      data-aos-duration="1000"
      style={{ color: isDark ? "#C4C1CE" : "#121212" }}
    >
      {text}
    </h2>
  </div>
)

export default sectionSubtitle
