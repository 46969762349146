import React from "react"
import "./styles.scss"

import Section from "../section"
import SectionSubtitle from "../sectionSubtitle"
import arrowIcon from "../../images/arrow-up-right.svg"

const Contact = ({ color }) => (
  <Section isContact color="transparent" title="Contattami">
    <div id="contact" className="contact-container fix-content-width">
      <div className="section-subtitle">
        <a href="mailto:stefano.maffina90@gmail.com">
          <h2 className="desktop-fix__hide" style={{ marginBottom: "48px" }}>
            <b>
              stefano.maffina90 <br />
              @gmail.com
            </b>
          </h2>
          <h2 className="desktop-fix__show" style={{ marginBottom: "80px" }}>
            <b>stefano.maffina90@gmail.com</b>
          </h2>
        </a>
      </div>
      <div className="footer-desktop-fix">
        <div className="info-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <span className="info-sm-title">Dott. Stefano Maffina</span>
            <span>Via Convento, 34</span>
            <span>25063, Gardone Val Trompia (BS)</span>
            <span>P.I. 04192820985</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "auto",
            }}
          >
            <span className="info-sm-title">Orari studio</span>
            <span style={{ fontSize: "16px" }}>Lun ~ Ven: 09:00 - 19:00</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "auto",
            }}
          >
            <span className="info-sm-title">Social</span>
            <div className="info-socials">
              <span className="social-name">
                <a
                  href="https://www.facebook.com/Stefano-Maffina-Psicologo-114685336937840"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook{" "}
                </a>

                <img src={arrowIcon}></img>
              </span>
              <span className="social-name">
                <a
                  href="https://www.instagram.com/stefanomaffina_psicologo/"
                  target="_blank"
                >
                  Instagram{" "}
                </a>
                <img src={arrowIcon}></img>
              </span>
              <span className="social-name">
                <a href="https://wa.me/3801966005" target="_blank">
                  WhatsApp{" "}
                </a>
                <img src={arrowIcon}></img>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-map"></div>
    </div>
    <a
      href="https://www.google.com/maps/place/45%C2%B041'10.5%22N+10%C2%B010'57.8%22E/@45.6859923,10.182594,19z/data=!4m13!1m7!3m6!1s0x47817c0c0740e8cd:0xe65e4d55ca7fe283!2sVia+Convento,+25063+Gardone+Val+Trompia+BS!3b1!8m2!3d45.6853178!4d10.1833343!3m4!1s0x0:0x0!8m2!3d45.6862773!4d10.1827452"
      target="_blank"
    >
      <div className="footer-map"></div>
    </a>
  </Section>
)

export default Contact
