import React from "react"
import "./styles.scss"

import SectionTitle from "../sectionTitle"

const Section = ({ children, title, color, isContact, isAbout }) => (
  <section
    className="section-container"
    style={{ backgroundColor: `${color}` }}
  >
    <div className="fix-content-width">
      <div
        style={{ display: isAbout ? "none" : "block" }}
        className="line-top"
      />
      <div style={{ display: isAbout ? "none" : "block" }}>
        <SectionTitle
          style={{ display: isAbout ? "none" : "block" }}
          title={title}
        />
      </div>
      <div className="content">{children}</div>
      <div
        style={{ display: isContact ? "none" : "block" }}
        className="line-bottom"
      />
    </div>
  </section>
)

export default Section
