import React from "react"
import "./styles.scss"

const sectionTitle = ({ title }) => (
  <div className="sectionTitle">
    <h1 data-aos="custom-fade">{title}</h1>
    <div
      data-aos="custom-border"
      data-aos-duration="1000"
      className="section-title__border"
    ></div>
  </div>
)

export default sectionTitle
