import React from "react"
import "./styles.scss"

const footer = ({ color }) => (
  <div className="footer-color">
    <div className="fix-content-width footer-container">
      <div className="footer-container-copy">
        <span>&#169; 2020 Stefano Maffina</span>{" "}
      </div>
      <div className="footer-container-links">
        <span>Privacy policy</span>
        <span style={{ marginLeft: "8px" }}>Credits</span>
      </div>
    </div>
  </div>
)

export default footer
