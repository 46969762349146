import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"
import "../components/singlepage.scss"

const MethodPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
        description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
      />
      <div className="fix-content-width">
        <NavBar />
        <div className="singlepage-container">
          <div className="section-subtitle singlepage-title">
            <h2
              data-aos="custom-fade"
              data-aos-duration="1000"
              style={{ color: "#121212" }}
            >
              <span style={{ fontWeight: 700, textDecoration: "italic" }}>
                Il mio approccio
              </span>
            </h2>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="singlepage-title-border"
            ></div>
            <div className="fix-content-width why-content">
              <div style={{ marginTop: "64px", marginBottom: "64px" }}>
                <p>
                  La terapia cognitivo comportamentale è attualmente considerata
                  uno dei più efficaci approcci per la comprensione e il
                  trattamento dei disturbi psicologici.
                </p>
                <p>
                  Si basa sul presupposto che vi sia una correlazione tra
                  pensieri, emozioni e comportamenti.
                </p>
                <p>
                  La psicoterapia cognitivo comportamentale è un metodo
                  scientificamente fondato, che si basa sulle conoscenze delle
                  strutture e dei processi mentali pervenute dalla ricerca
                  psicologica di base.
                </p>
                <p>
                  Attualmente, questa strategia è considerata, a livello
                  internazionale, uno dei più efficaci metodi per la
                  comprensione ed il trattamento dei disturbi psicopatologici.
                </p>
                <p>
                  L'efficacia di quest'approccio è stata dimostrata da studi
                  scientifici realizzati in condizioni controllate, condotti con
                  lo stesso rigore dei test effettuati per le terapie
                  farmacologiche.
                </p>
                <p>
                  Lo scopo della terapia è quello di riconoscere e superare ciò
                  che genera sofferenza attraverso lo sviluppo di modalità di
                  pensiero più adeguate e strategie comportamentali funzionali
                  rispetto agli obiettivi del singolo individuo.
                </p>
                <p>
                  Il trattamento si propone, quindi, di migliorare la qualità
                  della vita dei pazienti ed aiutarli a gestire o risolvere
                  l'eventuale psicopatologia.
                </p>
                <p>
                  La psicoterapia cognitivo comportamentale fornisce gli
                  strumenti per individuare gli schemi distorti di ragionamento
                  e d'interpretazione della realtà, quindi li integra con
                  pensieri e convinzioni funzionali e positive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </Layout>
  )
}

export default MethodPage
